import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Loader from "../../sections/loader/Loader";
import toast from "react-hot-toast";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import imageCompression from "browser-image-compression";

function HomeAboutUs() {
  const [title, setTitle] = useState("");
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [frontImageAltText, setFrontImageAltText] = useState("");
  const [backImageAltText, setBackImageAltText] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [imagePreviews, setImagePreviews] = useState({ front: "", back: "" });
  const [data, setData] = useState();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleImageChange = async (e, setImage, previewKey) => {
    const file = e.target.files[0];
    setImage(file);

    if (file) {
      if (file.size > 1024 * 1024) {
        // Check if file size is greater than 1MB
        toast.error("File size should be less than 1MB");
        return;
      }

      const options = {
        // Image compression options
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options); // Compress image
        console.log("Compressed file:", compressedFile);
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Image = reader.result;
          console.log("Base64 image:", base64Image);
          setImagePreviews((prev) => ({ ...prev, [previewKey]: base64Image }));
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
        toast.error("Error compressing image");
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const newErrors = {};

    if (!title) newErrors.title = "Title is required";
    if (!frontImageAltText)
      newErrors.frontImageAltText = "Front alt text is required";
    if (!backImageAltText)
      newErrors.backImageAltText = "Back alt text is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        await axios({
          method: "put",
          url: `https://api.smilecloudfamilydental.com/smile-dental/about_us/1`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            title: title,
            image: frontImage ? imagePreviews.front : data.front_image,
            image1: backImage ? imagePreviews.back : data.back_image,
            front_image_alt_text: frontImageAltText,
            back_image_alt_text: backImageAltText,
            description: description,
          },
        });
        toast.success("Updated successfully");
        setEditMode(false);
        setTimeout(fetchData, 3000); // Delay fetching data by 1 second
      } catch (error) {
        toast.error("Error in updating");
        console.error("Error submitting the form:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.smilecloudfamilydental.com/smile-dental/about_us"
      );
      const fetchedData = response?.data?.[0];
      setData(fetchedData);
      setTitle(fetchedData?.title || "");
      setFrontImageAltText(fetchedData?.front_image_alt_text || "");
      setBackImageAltText(fetchedData?.back_image_alt_text || "");
      setDescription(fetchedData?.description || "");
      setImagePreviews({
        front: fetchedData?.front_image || "",
        back: fetchedData?.back_image || "",
      });
    } catch (error) {
      toast.error("Error fetching the data:", error);
      console.error("Error fetching the data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      {loading && <Loader />}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">About Us</Typography>
      </Stack>

      <Card sx={{ padding: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              onClick={() =>
                editMode &&
                document?.getElementById("front-image-input")?.click()
              }
              sx={{
                border: "2px dashed gray",
                borderRadius: "4px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              {imagePreviews.front ? (
                <img
                  src={imagePreviews.front}
                  alt="Front"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              ) : (
                <Typography>Click here to add Front Image</Typography>
              )}
              {editMode && (
                <input
                  type="file"
                  hidden
                  id="front-image-input"
                  accept="image/*"
                  onChange={(e) =>
                    editMode && handleImageChange(e, setFrontImage, "front")
                  }
                />
              )}
            </Box>
            {editMode && errors.frontImageAltText && (
              <Typography variant="body2" color="error">
                {errors.frontImageAltText}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              onClick={() =>
                editMode &&
                document?.getElementById("back-image-input")?.click()
              }
              sx={{
                border: "2px dashed gray",
                borderRadius: "4px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              {imagePreviews.back ? (
                <img
                  src={imagePreviews.back}
                  alt="Back"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              ) : (
                <Typography>Click here to add Back Image</Typography>
              )}
              {editMode && (
                <input
                  type="file"
                  hidden
                  id="back-image-input"
                  accept="image/*"
                  onChange={(e) =>
                    editMode && handleImageChange(e, setBackImage, "back")
                  }
                />
              )}
            </Box>
            {editMode && errors.backImageAltText && (
              <Typography variant="body2" color="error">
                {errors.backImageAltText}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Front Image Alt Text"
              variant="outlined"
              value={frontImageAltText}
              onChange={(e) => setFrontImageAltText(e.target.value)}
              error={!!errors.frontImageAltText}
              helperText={errors.frontImageAltText}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Back Image Alt Text"
              variant="outlined"
              value={backImageAltText}
              onChange={(e) => setBackImageAltText(e.target.value)}
              error={!!errors.backImageAltText}
              helperText={errors.backImageAltText}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={!!errors.title}
              helperText={errors.title}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={6}>
            {!editMode ? (
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                error={!!errors.description}
                helperText={errors.description}
                disabled={!editMode}
              />
            ) : (
              <SunEditor
                setDefaultStyle="height: 200px"
                setContents={description}
                onChange={(e) => setDescription(e)}
                setOptions={{
                  buttonList: [
                    [
                      "bold",
                      "underline",
                      "italic",
                      "list",
                      "fontSize",
                      "fontColor",
                    ],
                  ],
                }}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              {editMode ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEdit}
                >
                  Edit
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

export default HomeAboutUs;
