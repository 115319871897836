import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  TableContainer,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../sections/loader/Loader";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";

function Headers() {
  const [open, setOpen] = useState(false);
  const [selectedHeader, setSelectedHeader] = useState(null);
  const [headerData, setHeaderData] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    page_name: "",
    logo_image: "",
    logo_image_alt_text: "",
  });
  const [logo, setLogo] = useState();

  const handleClickOpen = (header) => {
    setSelectedHeader(header);
    setFormValues({
      page_name: header.page_name,
      logo_image: header.image || logo,
      logo_image_alt_text: header.logo_image_alt_text,
    });
    setImagePreview(header.image || logo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedHeader(null);
    setImagePreview("");
    setFormValues({
      page_name: "",
      logo_image: "",
      logo_image_alt_text: "",
    });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setImagePreview(base64String);
          setFormValues({ ...formValues, logo_image: base64String });
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const payload = {
        page_name: formValues.page_name,
        logo_image_alt_text: formValues.logo_image_alt_text,
        page_url: selectedHeader.page_url,
        image: formValues.logo_image,
      };

      await axios({
        method: "put",
        url: `https://api.smilecloudfamilydental.com/smile-dental/headers/${selectedHeader?.id}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: payload,
      });
      fetchHeaders();
      toast.success("Update successfully");
      handleClose();
    } catch (error) {
      toast.error("Error in updating");
      console.error("Error updating header:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchHeaders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.smilecloudfamilydental.com/smile-dental/headers"
      );
      setHeaderData(response?.data?.data);
      setLogo(response?.data?.logo_image);
      console.log("response", response?.data?.logo_image);
    } catch (error) {
      console.error("Error fetching headers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHeaders();
  }, []);

  return (
    <Container>
      {loading && <Loader />}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Headers</Typography>
      </Stack>

      <Card className="Headers_card" sx={{ minHeight: "63vh" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="headers table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Page Name</TableCell>
                <TableCell>Logo Image</TableCell>
                <TableCell>Logo Image Alt Text</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {headerData?.map((header, index) => (
                <TableRow key={header.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{header.page_name}</TableCell>
                  <TableCell>
                    <img
                      src={header.image || logo}
                      alt={header.logo_image_alt_text}
                      width="50"
                      height="50"
                    />
                  </TableCell>
                  <TableCell>{header.logo_image_alt_text}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClickOpen(header)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Header</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                border="1px dashed grey"
                height={200}
                width="100%"
                onClick={() => document.getElementById("imageInput").click()}
                style={{ cursor: "pointer" }}
              >
                {imagePreview ? (
                  <img src={imagePreview} alt="Selected" height="200" />
                ) : (
                  <Typography>Click to select a logo image</Typography>
                )}
              </Box>
              <input
                type="file"
                id="imageInput"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="page_name"
                label="Page Name"
                type="text"
                fullWidth
                value={formValues.page_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="logo_image_alt_text"
                label="Logo Image Alt Text"
                type="text"
                fullWidth
                value={formValues.logo_image_alt_text}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Headers;
