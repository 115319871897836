import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function Loader() {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 9999
            }}
        >
            <CircularProgress style={{ color: '#FFFFFF' }} size={64} /> {/* Adjust size and color */}
        </Box>
    );
}
