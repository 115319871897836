import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../sections/loader/Loader";
import toast from "react-hot-toast";

function EnquiryList() {
  const [enquiryList, setEnquiryList] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletedClient, setDeletedClient] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.smilecloudfamilydental.com/smile-dental/inquiry_form"
      );
      setEnquiryList(response?.data);
    } catch (error) {
      console.error("Error fetching the data:", error);
    } finally {
      setLoading(false); // Set loading to false after submitting
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenDeleteDialog = (client) => {
    setOpenDeleteDialog(true);
    setDeletedClient(client);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await axios.delete(
        `https://api.smilecloudfamilydental.com/smile-dental/inquiry_form/${deletedClient?.id}`
      );
      setOpenDeleteDialog(false);
      fetchData();
      toast.success("deleted successfully");
    } catch (error) {
      console.error("Error deleting client:", error);
      toast.error("Error in deleting client");
    } finally {
      setLoading(false); // Set loading to false after deleting
    }
  };

  return (
    <Container>
      {loading && <Loader />}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Enquiry</Typography>
      </Stack>

      <Card sx={{ padding: "20px" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {enquiryList.map((client, index) => (
                <TableRow key={client.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{client.name}</TableCell>
                  <TableCell>{client.email}</TableCell>
                  <TableCell>{client.phone_number}</TableCell>
                  <TableCell>{client.subject}</TableCell>
                  <TableCell>{client.message}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenDeleteDialog(client)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Enquiry</DialogTitle>
        <DialogContent>
          <Typography>Do you want to delete?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default EnquiryList;
