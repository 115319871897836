import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios"; // Import axios for making HTTP requests
import Loader from "../sections/loader/Loader";
import toast from "react-hot-toast";

function MetaData() {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rows, setRows] = useState([]);
  const [formValues, setFormValues] = useState({
    page_name: "",
    meta_title: "",
    meta_description: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setErrors({});
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    if (value) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleEdit = (row) => {
    setSelectedRow(row);
    setFormValues({
      page_name: row.page_name || "",
      meta_title: row.meta_title || "",
      meta_description: row.meta_description || "",
    });
    setOpen(true);
  };

  const handleSave = async () => {
    setLoading(true); // Set loading to true when saving
    try {
      await axios({
        method: "put",
        url: `https://api.smilecloudfamilydental.com/smile-dental/metadata/${selectedRow?.id}`,
        data: {
          page_name: formValues.page_name,
          meta_title: formValues.meta_title,
          meta_description: formValues.meta_description,
        },
      });
      toast.success("Meta Data updated successfully");
      fetchCards(); // Fetch banners after successful update
      setOpen(false); // Close the dialog
      setLoading(false); // Set loading to false after saving
    } catch (error) {
      toast.error("Error in updating Meta Data");
      console.error("Error updating banner:", error);
      setLoading(false); // Set loading to false if there's an error
    }
  };

  const fetchCards = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const response = await axios.get(
        "https://api.smilecloudfamilydental.com/smile-dental/metadata"
      );
      setRows(response?.data);
    } catch (error) {
      console.error("Error fetching banners:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);
  return (
    <Container>
      {loading && <Loader />}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Meta Data</Typography>
      </Stack>

      <Card className="Headers_card" sx={{ minHeight: "63vh" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Page Name</TableCell>
                <TableCell>Meta Title</TableCell>
                <TableCell>Meta Description</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.page_name}</TableCell>
                  <TableCell>{row.meta_title}</TableCell>
                  <TableCell>{row.meta_description}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Banner List</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="page_name"
                label="Page Name"
                type="text"
                fullWidth
                value={formValues.page_name}
                disabled // Make the "Page Name" field disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="meta_title"
                label="Meta Title"
                type="text"
                fullWidth
                value={formValues.meta_title}
                onChange={handleChange}
                error={!!errors.meta_title}
                helperText={errors.meta_title}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    name="meta_description"
                    label="Meta Description"
                    type="text"
                    fullWidth
                    value={formValues.meta_description}
                    onChange={handleChange}
                    error={!!errors.meta_description}
                    helperText={errors.meta_description}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default MetaData;
