import { useState } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import { alpha, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";

import { useRouter } from "../../routes/hooks";

import { bgGradient } from "../../theme/css";

import Logo from "../../components/logo";
import Iconify from "../../components/iconify";
import Loader from "../loader/Loader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";

// ----------------------------------------------------------------------

export default function LoginView() {
  const theme = useTheme();

  const router = useRouter();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false); // State for loader
  const [responseError, setResponseError] = useState(""); // State for loader
  const [open, setOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  const handleClick = async () => {
    if (!email.trim() || !password.trim()) {
      setError(true);
      setErrorText("Email and password are required");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        "https://api.smilecloudfamilydental.com/smile-dental/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      if (!response.ok) {
        throw new Error("Please enter valid credentials");
      }
      const data = await response.json();
      localStorage.setItem("isAuthenticated", data);

      console.log("data", data);
      router.push("/");
    } catch (err) {
      console.error("Error during login:", err);
      setResponseError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = (password) => {
    setPassword(password);
  };

  const handleEmailChange = (email) => {
    setError(false);
    setErrorText("");
    if (!email.trim()) {
      setError(true);
      setErrorText("Email address is required");
      return;
    }
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      setError(true);
      setErrorText("Please enter a valid email address");
      return;
    }
    setEmail(email);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendEmail = async () => {
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!regex.test(resetEmail)) {
    //   setResponseError('Please enter a valid email address');
    //   return;
    // }

    setLoading(true);
    try {
      const response = await fetch(
        "https://api.smilecloudfamilydental.com/smile-dental/forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: resetEmail }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send password reset email");
      }
      // You can handle the success response here if needed
      toast.success("Password reset email sent successfully");
      console.log("Password reset email sent successfully");
      setOpen(false);
    } catch (err) {
      console.error("Error during password reset:", err);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Box
        sx={{
          ...bgGradient({
            color: alpha(theme.palette.background.default, 0.9),
            imgUrl: "/assets/background/overlay_4.jpg",
          }),
          height: 1,
        }}
      >
        <Logo
          sx={{
            position: "fixed",
            top: { xs: 16, md: 24 },
            left: { xs: 16, md: 24 },
          }}
        />

        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
          <Card
            sx={{
              p: 5,
              width: 1,
              maxWidth: 420,
            }}
          >
            <Typography variant="h4">Sign in to Smile dental</Typography>

            <Divider sx={{ my: 3 }} />

            <Stack spacing={3}>
              <TextField
                name="email"
                label="Email address"
                onChange={(e) => handleEmailChange(e?.target?.value)}
                error={error}
                helperText={error && errorText}
              />

              <TextField
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => handlePasswordChange(e?.target?.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            {responseError && (
              <Typography color="error">{responseError}</Typography>
            )}
            <Typography
              variant="body2"
              sx={{
                textAlign: "right",
                marginTop: 2,
                "&:hover": {
                  textDecoration: "underline",
                  color: "primary.main",
                  cursor: "pointer",
                },
              }}
              onClick={handleClickOpen}
            >
              Forgot password?
            </Typography>
            <Divider sx={{ my: 3 }} />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="inherit"
              onClick={handleClick}
            >
              Login
            </LoadingButton>
          </Card>
        </Stack>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="reset-password-dialog-title"
      >
        <DialogTitle id="reset-password-dialog-title">
          Reset Password
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body2"
            sx={{
              mb: 2, // Margin bottom
              color: theme.palette.text.secondary,
              fontSize: "16px",
              fontWeight: 400,
            }}
          >
            Please enter your email address to receive a password reset link.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="reset-email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            onChange={(e) => setResetEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendEmail} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
