import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  TextField,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Loader from "../../sections/loader/Loader";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression"; // Import image compression library

function Banner() {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rows, setRows] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [formValues, setFormValues] = useState({
    title: "",
    page_name: "",
    image: "",
    image_alt_text: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleEdit = (row) => {
    setSelectedRow(row);
    setFormValues({
      title: row.title || "",
      page_name: row.page_name || "",
      image: row.image || "",
      image_alt_text: row.image_alt_text || "",
      description: row.description || "",
    });
    setOpen(true);
    setImagePreview(row.image || "");
  };

  const handleClose = () => {
    setOpen(false);
    setImagePreview("");
    setErrors({});
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        // Check if file size is greater than 1MB
        toast.error("File size should be less than 1MB");
        return;
      }

      const options = {
        // Image compression options
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options); // Compress image
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
        toast.error("Error compressing image");
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    if (value) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await axios({
        method: "put",
        url: `https://api.smilecloudfamilydental.com/smile-dental/carousel/${selectedRow?.id}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: {
          title: formValues.title,
          image: imagePreview,
          image_alt_text: formValues.image_alt_text,
          page_name: formValues.page_name,
          description: formValues.description,
        },
      });
      fetchBanners();
      setOpen(false);
      setLoading(false);
      toast.success("updated successfully");
    } catch (error) {
      toast.error("Error in updating");
      console.error("Error updating banner:", error);
      setLoading(false);
    }
  };

  const fetchBanners = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.smilecloudfamilydental.com/smile-dental/carousel"
      );
      setRows(response?.data);
    } catch (error) {
      console.error("Error fetching banners:", error);
      toast.error("Error fetching banners");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <Container>
      {loading && <Loader />}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Banner</Typography>
      </Stack>

      <Card className="Headers_card" sx={{ minHeight: "63vh" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Page Name</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Image Alt Text</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.page_name}</TableCell>
                  <TableCell>
                    {" "}
                    {row.image ? (
                      <img
                        src={row.image}
                        alt={row.image_alt_text}
                        height="50"
                      />
                    ) : (
                      "No Image"
                    )}
                  </TableCell>
                  <TableCell>{row.image_alt_text}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Banner List</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                border="1px dashed grey"
                height={200}
                width="100%"
                onClick={() => document.getElementById("imageInput").click()}
                style={{ cursor: "pointer" }}
              >
                {imagePreview ? (
                  <img src={imagePreview} alt="Selected" height="200" />
                ) : (
                  <Typography>Click to select an image</Typography>
                )}
              </Box>
              <input
                type="file"
                id="imageInput"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="page_name"
                label="Page Name"
                type="text"
                fullWidth
                value={formValues.page_name}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="title"
                label="Title"
                type="text"
                fullWidth
                value={formValues.title}
                onChange={handleChange}
                error={!!errors.title}
                helperText={errors.title}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    name="image_alt_text"
                    label="Image Alt Text"
                    type="text"
                    fullWidth
                    value={formValues.image_alt_text}
                    onChange={handleChange}
                    error={!!errors.image_alt_text}
                    helperText={errors.image_alt_text}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    name="description"
                    label="Description"
                    type="text"
                    fullWidth
                    multiline
                    value={formValues.description}
                    onChange={handleChange}
                    error={!!errors.description}
                    helperText={errors.description}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Banner;
