import { Suspense, useEffect, useState } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';
import LoginPage from '../pages/login';
import Headers from '../pages/Headers';
import Footers from '../pages/Footers';
import MetaData from '../pages/MetaData';
import AwardsAndHonours from '../pages/AwardsAndHonours';
import Location from '../pages/Location';
import PersonalInformation from '../pages/PersonalInformation';
import AllServices from '../pages/AllServices';
import Home from '../pages/Home';
import Banner from './../pages/HomePages/Banner';
import Blogs from './../pages/HomePages/Blogs';
import EnquiryList from '../pages/EnquiryList';
import HomeAboutUs from '../pages/HomePages/HomeAboutUs';
import Map from './../pages/HomePages/Map';
import Availability from './../pages/HomePages/Availability';
import Testimonials from './../pages/HomePages/Testimonials';
import ProfessionalInformation from '../pages/AboutUs/ProfessionalInformation';
import Cards from '../pages/HomePages/Cards';
import Services from './../pages/HomePages/Services';
import MakeAppointment from './../pages/HomePages/MakeAppointment';
import Footer from './../pages/HomePages/Footer';
import LocationCards from '../pages/LocationCards';
import Page404 from '../pages/page-not-found';
import Loader from '../sections/loader/Loader';

// ----------------------------------------------------------------------

// This function simulates checking if a user is logged in
const useAuth = () => {
  // Replace this with your actual authentication logic
  const isAuthenticated = !!localStorage.getItem('isAuthenticated');
  return { isAuthenticated };
};

// AuthGuard component to protect routes
export function AuthGuard() {
  const { isAuthenticated } = useAuth();
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    // Simulate an async check, e.g., fetching user session from an API
    const checkAuth = async () => {
      // Add your authentication logic here
      setAuthChecked(true); // Assuming authentication check is completed
    };

    checkAuth();
  }, []);

  if (!authChecked) {
    return <Loader/>; // Or a spinner
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
}

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </AuthGuard>
      ),
      children: [
        { element: <EnquiryList />, index: true },
        { path: '/Headers', element: <Headers /> },
        { path: '/Home/Banner', element: <Banner /> },
        { path: '/Home/Availability', element: <Availability /> },
        { path: '/Home/HomeAboutUs', element: <HomeAboutUs /> },
        // { path: 'Home/Cards', element: <Cards /> },
        { path: '/Home/Testimonials', element: <Testimonials /> },
        { path: '/Home/Services', element: <Services /> },
        { path: '/Home/Make_Appointment', element: <MakeAppointment /> },
        { path: '/Home/Blogs', element: <Blogs /> },
        { path: '/Home/Map', element: <Map /> },
        { path: '/Home/Footer', element: <Footer /> },
        { path: '/AboutUs/ProfessionalInformation', element: <ProfessionalInformation /> },
        { path: '/Footers', element: <Footers /> },
        { path: '/MetaData', element: <MetaData /> },
        { path: '/Personal_Information', element: <PersonalInformation /> },
        { path: '/Awards_Honours', element: <AwardsAndHonours /> },
        { path: '/Location', element: <Location /> },
        { path: '/All_Services', element: <AllServices /> },
        { path: '/Location_Cards', element: <LocationCards /> },
        { path: '/Home', element: <Home /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
