import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../sections/loader/Loader";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression"; // Import image compression library
import { Navigate, useNavigate } from "react-router-dom";

const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + " .......";
  }
  return text;
};

function Testimonials() {
  const [clients, setClients] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientName, setClientName] = useState("");
  const [clientImage, setClientImage] = useState(null);
  const [clientImageAltText, setClientImageAltText] = useState("");
  const [clientRating, setClientRating] = useState("");
  const [clientFeedback, setClientFeedback] = useState("");
  const [clientFeedbackDescription, setClientFeedbackDescription] =
    useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const handleOpen = (client) => {
    setSelectedClient(client);
    setClientName(client?.client_name || "");
    setClientImage(client?.client_image || null);
    setClientImageAltText(client?.client_image_alt_text || "");
    setClientRating(client?.client_rating || "");
    setClientFeedback(client?.client_feedback || "");
    setClientFeedbackDescription(client?.client_feedback_description || "");
    setOpen(true);
  };

  const handleOpenAddClient = () => {
    setSelectedClient(null);
    setClientName("");
    setClientImage(null);
    setClientImageAltText("");
    setClientRating("");
    setClientFeedback("");
    setClientFeedbackDescription("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
  };

  const handleImageChange = async (e) => {
    const file = e?.target?.files[0];
    // if (file) {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         setClientImage(reader.result);
    //     };
    //     reader.readAsDataURL(file);
    // }

    if (file) {
      if (file.size > 1024 * 1024) {
        // Check if file size is greater than 1MB
        toast.error("File size should be less than 1MB");
        return;
      }

      const options = {
        // Image compression options
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options); // Compress image
        const reader = new FileReader();
        reader.onloadend = () => {
          setClientImage(reader.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
        toast.error("Error compressing image");
      }
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true); // Set loading to true before delete request
      await axios.delete(
        `https://api.smilecloudfamilydental.com/smile-dental/testimonials/${selectedClient.id}`
      );
      setClients((prevClients) =>
        prevClients.filter((client) => client.id !== selectedClient.id)
      );
      setOpenDeleteDialog(false);
      setSelectedClient(null);
      toast.success("Client deleted successfully");
    } catch (error) {
      toast.error("Error deleting client:", error);
      console.error("Error deleting client:", error);
    } finally {
      setLoading(false); // Set loading to false after delete request
    }
  };

  const handleOpenDeleteDialog = (client) => {
    setSelectedClient(client);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedClient(null);
  };

  const handleSubmit = async () => {
    const newErrors = {};
    if (!clientName) newErrors.clientName = "Client name is required";
    if (!clientImage) newErrors.clientImage = "Client image is required";
    if (!clientImageAltText)
      newErrors.clientImageAltText = "Client image alt text is required";
    if (!clientRating) newErrors.clientRating = "Client rating is required";
    if (!clientFeedback)
      newErrors.clientFeedback = "Client feedback is required";
    if (!clientFeedbackDescription)
      newErrors.clientFeedbackDescription =
        "Client feedback description is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true); // Set loading to true before form submission
      if (selectedClient) {
        try {
          await axios({
            method: "put",
            url: `https://api.smilecloudfamilydental.com/smile-dental/testimonials/${selectedClient.id}`,
            data: {
              client_name: clientName,
              image: clientImage,
              client_feedback: clientFeedback,
              client_rating: clientRating,
              client_feedback_description: clientFeedbackDescription,
              client_image_alt_text: clientImageAltText,
            },
          });
          fetchData();
          handleClose();
          toast.success("Client edited successfully");
          navigate("/home/testimonials");
        } catch (error) {
          toast.error("Error editing the client:", error);
          console.error("Error editing the client:", error);
        }
      } else {
        try {
          await axios({
            method: "POST",
            url: "https://api.smilecloudfamilydental.com/smile-dental/testimonials",
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: {
              client_name: clientName,
              image: clientImage,
              client_feedback: clientFeedback,
              client_rating: clientRating,
              client_feedback_description: clientFeedbackDescription,
              client_image_alt_text: clientImageAltText,
            },
          });
          fetchData();
          handleClose();
          toast.success("Client added successfully");
        } catch (error) {
          toast.error("Error adding the client:", error);
          console.error("Error adding the client:", error);
        }
      }
      setLoading(false); // Set loading to false after form submission
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true); // Set loading to true before fetching
      const response = await axios.get(
        "https://api.smilecloudfamilydental.com/smile-dental/testimonials"
      );
      setClients(response?.data);
    } catch (error) {
      toast.error("Error fetching the data:", error);
      console.error("Error fetching the data:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Clients</Typography>
        <Button variant="contained" onClick={handleOpenAddClient}>
          Add Client
        </Button>
      </Stack>

      <Card sx={{ padding: "20px" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Client Name</TableCell>
                <TableCell>Client Image</TableCell>
                <TableCell>Client Image Alt Text</TableCell>
                <TableCell>Client Rating</TableCell>
                <TableCell>Client Feedback</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map((client, index) => (
                <TableRow key={client.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{client.client_name}</TableCell>
                  <TableCell>
                    {client.client_image && (
                      <img
                        src={client.client_image}
                        alt={client.client_image_alt_text || client.client_name}
                        style={{ width: 50, height: 50 }}
                      />
                    )}
                  </TableCell>
                  <TableCell>{client.client_image_alt_text}</TableCell>
                  <TableCell>{client.client_rating}</TableCell>
                  <TableCell>
                    {truncateText(client.client_feedback_description, 10)}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpen(client)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteDialog(client)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {selectedClient ? "Edit Client" : "Add Client"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                name="clientName"
                label="Client Name"
                type="text"
                fullWidth
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                error={!!errors.clientName}
                helperText={errors.clientName}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                border="1px dashed grey"
                height={200}
                width="100%"
                onClick={() =>
                  document.getElementById("clientImageInput").click()
                }
                style={{ cursor: "pointer" }}
              >
                {clientImage ? (
                  <img
                    src={
                      typeof clientImage === "string"
                        ? clientImage
                        : URL.createObjectURL(clientImage)
                    }
                    alt={clientImageAltText}
                    height="200"
                  />
                ) : (
                  <Typography>Click to select an image</Typography>
                )}
              </Box>

              <input
                type="file"
                id="clientImageInput"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                name="clientImageAltText"
                label="Client Image Alt Text"
                type="text"
                fullWidth
                value={clientImageAltText}
                onChange={(e) => setClientImageAltText(e.target.value)}
                error={!!errors.clientImageAltText}
                helperText={errors.clientImageAltText}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                name="clientRating"
                label="Client Rating"
                type="number"
                fullWidth
                value={clientRating}
                onChange={(e) => setClientRating(e.target.value)}
                error={!!errors.clientRating}
                helperText={errors.clientRating}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                name="clientFeedback"
                label="Client Feedback"
                type="text"
                fullWidth
                value={clientFeedback}
                onChange={(e) => setClientFeedback(e.target.value)}
                error={!!errors.clientFeedback}
                helperText={errors.clientFeedback}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                name="clientFeedbackDescription"
                label="Client Feedback Description"
                type="text"
                fullWidth
                multiline
                value={clientFeedbackDescription}
                onChange={(e) => setClientFeedbackDescription(e.target.value)}
                error={!!errors.clientFeedbackDescription}
                helperText={errors.clientFeedbackDescription}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          {loading ? (
            <Loader /> // Show loader while submitting
          ) : (
            <Button onClick={handleSubmit} color="primary" variant="contained">
              {selectedClient ? "Save" : "Add"}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Client</DialogTitle>
        <DialogContent>
          <Typography>
            Do you want to delete {selectedClient?.client_name}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          {loading ? (
            <Loader /> // Show loader while deleting
          ) : (
            <Button
              onClick={handleDelete}
              color="secondary"
              variant="contained"
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Testimonials;
