import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'Enquiry List',
    path: '/',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Meta Data',
    path: '/MetaData',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Headers',
    path: '/Headers',
    icon: icon('ic_blog'),
  },
  {
    title: 'Home',
    path: '/Home',
    icon: icon('ic_blog'),
    children: [
      {
        title: 'Banner',
        path: '/Home/Banner',
        icon: icon('ic_blog'),
      },
      {
        title: 'Availability',
        path: '/Home/Availability',
        icon: icon('ic_blog'),
      },
      {
        title: 'Testimonials',
        path: '/Home/Testimonials',
        icon: icon('ic_blog'),
      },
    ],
  },
  {
    title: 'About Us',
    path: '/Home/HomeAboutUs',
    icon: icon('ic_blog'),
    children: [
      {
        title: 'Main Content',
        path: '/Home/HomeAboutUs',
        icon: icon('ic_blog'),
      },
      {
        title: 'Professional Info',
        path: '/AboutUs/ProfessionalInformation',
        icon: icon('ic_blog'),
      },
    ],
  },
  {
    title: 'Services',
    path: '/Home/Services',
    icon: icon('ic_blog'),
  },
  {
    title: 'Blogs',
    path: '/Home/Blogs',
    icon: icon('ic_blog'),
  },
  // {
  //   title: 'All Cards',
  //   path: '/Home/Cards',
  //   icon: icon('ic_blog'),
  // },
  {
    title: 'Footer',
    path: '/Home/Footer',
    icon: icon('ic_blog'),
  },
];

export default navConfig;
