import { Button, Card, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

function Footers() {
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">Headers</Typography>
      </Stack>

      <Card className="Headers_card" sx={{ minHeight: '63vh' }}>
        <Grid container spacing={2} sx={{ padding: '40px' }}>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth name="Title" label="Title" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth name="email2" label="Email address" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth name="email3" label="Email address" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth name="email4" label="Email address" />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <Button variant="contained" color="primary">
                Submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

export default Footers;
