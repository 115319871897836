import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  TextField,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios"; // Import axios for making HTTP requests
import Loader from "../../sections/loader/Loader";
import toast from "react-hot-toast";

function Cards() {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rows, setRows] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [formValues, setFormValues] = useState({
    card_title: "",
    page_name: "",
    card_logo: "",
    card_logo_alt_text: "",
    card_description1: "",
    card_description2: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // State for loader

  const handleEdit = (row) => {
    setSelectedRow(row);
    setFormValues({
      card_title: row.card_title || "",
      page_name: row.page_name || "",
      card_logo: row.card_logo || "",
      card_logo_alt_text: row.card_logo_alt_text || "",
      card_description1: row.card_description1 || "",
      card_description2: row.card_description2 || "",
    });
    setOpen(true);
    setImagePreview(row.card_logo || "");
  };

  const handleClose = () => {
    setOpen(false);
    setImagePreview("");
    setErrors({});
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    if (value) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleSave = async () => {
    setLoading(true); // Set loading to true when saving
    try {
      await axios({
        method: "put",
        url: `https://api.smilecloudfamilydental.com/smile-dental/cards/home/${selectedRow?.id}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: {
          card_title: formValues.card_title,
          card_logo: imagePreview,
          card_logo_alt_text: formValues.card_logo_alt_text,
          page_name: formValues.page_name,
          card_description1: formValues.card_description1,
          card_description2: formValues.card_description2,
        },
      });
      toast.success("Card updated successfully");
      fetchCards(); // Fetch banners after successful update
      setOpen(false); // Close the dialog
      setLoading(false); // Set loading to false after saving
    } catch (error) {
      toast.error("Error in updating card");
      console.error("Error updating banner:", error);
      setLoading(false); // Set loading to false if there's an error
    }
  };

  const fetchCards = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const response = await axios.get(
        "https://api.smilecloudfamilydental.com/smile-dental/cards/home"
      );
      setRows(response?.data);
    } catch (error) {
      console.error("Error fetching banners:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <Container>
      {loading && <Loader />}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Cards</Typography>
      </Stack>

      <Card className="Headers_card" sx={{ minHeight: "63vh" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Page Name</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Image Alt Text</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.card_title}</TableCell>
                  <TableCell>{row.page_name}</TableCell>
                  <TableCell>
                    {" "}
                    {row.card_logo ? (
                      <img
                        src={row.card_logo}
                        alt={row.card_logo_alt_text}
                        height="50"
                      />
                    ) : (
                      "No Image"
                    )}
                  </TableCell>
                  <TableCell>{row.card_logo_alt_text}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Banner List</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                border="1px dashed grey"
                height={200}
                width="100%"
                onClick={() => document.getElementById("imageInput").click()}
                style={{ cursor: "pointer" }}
              >
                {imagePreview ? (
                  <img src={imagePreview} alt="Selected" height="200" />
                ) : (
                  <Typography>Click to select an card_logo</Typography>
                )}
              </Box>
              <input
                type="file"
                id="imageInput"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="page_name"
                label="Page Name"
                type="text"
                fullWidth
                value={formValues.page_name}
                disabled // Make the "Page Name" field disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="card_title"
                label="Title"
                type="text"
                fullWidth
                value={formValues.card_title}
                onChange={handleChange}
                error={!!errors.card_title}
                helperText={errors.card_title}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    name="card_logo_alt_text"
                    label="Image Alt Text"
                    type="text"
                    fullWidth
                    value={formValues.card_logo_alt_text}
                    onChange={handleChange}
                    error={!!errors.card_logo_alt_text}
                    helperText={errors.card_logo_alt_text}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    name="card_description1"
                    label="Description"
                    type="text"
                    fullWidth
                    multiline
                    value={formValues.card_description1}
                    onChange={handleChange}
                    error={!!errors.card_description1}
                    helperText={errors.card_description1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    name="card_description2"
                    label="Description2"
                    type="text"
                    fullWidth
                    multiline
                    value={formValues.card_description2}
                    onChange={handleChange}
                    error={!!errors.card_description2}
                    helperText={errors.card_description2}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Cards;
