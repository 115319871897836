import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../sections/loader/Loader";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression"; // Import image compression library

function Footer() {
  const [title, setTitle] = useState("");
  const [imageAltText, setImageAltText] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false); // State for loader

  const handleSubmit = async () => {
    const newErrors = {};
    if (!title) newErrors.title = "Title is required";
    if (!imageAltText) newErrors.imageAltText = "Image alt text is required";
    if (!description) newErrors.description = "Description is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("image", imagePreview);
        formData.append("image_alt_text", imageAltText);
        formData.append("description", description);

        await axios({
          method: "put",
          url: `https://api.smilecloudfamilydental.com/smile-dental/footer/1`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        fetchData();
        setEditMode(false);
        toast.success("Updated successfully");
      } catch (error) {
        toast.error("Error in updating footer");
        console.error("Error submitting the form:", error);
      } finally {
        setLoading(false); // Set loading to false after submitting
      }
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.smilecloudfamilydental.com/smile-dental/footer"
      );
      const fetchedData = response?.data?.[0];
      setTitle(fetchedData?.title || "");
      setImageAltText(fetchedData?.image_alt_text || "");
      setDescription(fetchedData?.description || "");
      setImagePreview(fetchedData?.image || null);
    } catch (error) {
      console.error("Error fetching the data:", error);
    } finally {
      setLoading(false); // Set loading to false after submitting
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        // Check if file size is greater than 1MB
        toast.error("File size should be less than 1MB");
        return;
      }
      // const reader = new FileReader();
      // reader.onload = () => {
      //     setImagePreview(reader.result);
      // };
      // reader.readAsDataURL(file);
      const options = {
        // Image compression options
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options); // Compress image
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
        toast.error("Error compressing image");
      }
    } else {
      setImagePreview(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = () => {
    setEditMode(true); // Set edit mode to true when Edit button is clicked
  };

  return (
    <Container>
      {loading && <Loader />}
      <Typography variant="h4">Home Footer</Typography>
      <Card sx={{ padding: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} container direction="column" spacing={2}>
            <Grid item>
              <Box
                onClick={() =>
                  editMode && document.getElementById("image-input").click()
                }
                sx={{
                  border: "2px dashed gray",
                  borderRadius: "4px",
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Image"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                ) : (
                  <Typography>Click here to add Image</Typography>
                )}
                <input
                  type="file"
                  hidden
                  id="image-input"
                  accept="image/*"
                  onChange={editMode && handleImageChange}
                />
              </Box>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Image Alt Text"
                variant="outlined"
                value={imageAltText}
                onChange={(e) => setImageAltText(e.target.value)}
                error={!!errors.imageAltText}
                helperText={errors.imageAltText}
                disabled={!editMode}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Title"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                error={!!errors.title}
                helperText={errors.title}
                disabled={!editMode}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                error={!!errors.description}
                helperText={errors.description}
                disabled={!editMode}
              />
            </Grid>
            <Grid item>
              {editMode ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEdit}
                >
                  Edit
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

export default Footer;
