import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Loader from "../../sections/loader/Loader";
import toast from "react-hot-toast";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const Availability = () => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({
    id: "",
    title: "",
    start_day: "",
    end_day: "",
    start_time: "",
    end_time: "",
  });
  const [loading, setLoading] = useState(false);

  const handleEdit = (row) => {
    setCurrentRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentRow({ ...currentRow, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await axios({
        method: "put",
        url: `https://api.smilecloudfamilydental.com/smile-dental/schedule_hours/${currentRow?.id}`,
        data: {
          title: currentRow.title,
          start_day: currentRow.start_day,
          end_day: currentRow.end_day,
          start_time: currentRow.start_time,
          end_time: currentRow.end_time,
        },
      });
      fetchData(); // Fetch banners after successful update
      setOpen(false); // Close the dialog
      setLoading(false);
      toast.success("updated successfully");
    } catch (error) {
      toast.error("Error in updating");
      console.error("Error updating banner:", error);
      setLoading(false);
    }
    // setRows(rows.map((row) => (row.id === currentRow.id ? currentRow : row)));
    setOpen(false);
  };

  const fetchData = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const response = await axios.get(
        "https://api.smilecloudfamilydental.com/smile-dental/schedule_hours"
      );
      setRows(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      {loading && <Loader />}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Schedule Table</Typography>
      </Stack>

      <Card className="Headers_card" sx={{ minHeight: "63vh" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Start Day</TableCell>
                <TableCell>End Day</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.start_day}</TableCell>
                  <TableCell>{row.end_day}</TableCell>
                  <TableCell>{row.start_time}</TableCell>
                  <TableCell>{row.end_time}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Schedule</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="title"
                label="Title"
                type="text"
                fullWidth
                value={currentRow.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                value={currentRow.start_day}
                onChange={handleChange}
                name="start_day"
                fullWidth
                displayEmpty
              >
                {daysOfWeek.map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Select
                value={currentRow.end_day}
                onChange={handleChange}
                name="end_day"
                fullWidth
                displayEmpty
              >
                {daysOfWeek.map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="start_time"
                label="Start Time"
                type="time"
                fullWidth
                value={currentRow.start_time}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                name="end_time"
                label="End Time"
                type="time"
                fullWidth
                value={currentRow.end_time}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Availability;
