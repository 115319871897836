import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import Loader from "../../sections/loader/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import toast from "react-hot-toast";

const ProfessionalInformation = () => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [formValues, setFormValues] = useState({
    name: "",
    specially: "",
    phone_number: "",
    email: "",
    experience: "",
    degrees: "",
    professional_summary: "",
    image: "",
    image_alt_text: "",
  });

  // State for SunEditor content
  const [professionalSummaryContent, setProfessionalSummaryContent] =
    useState("");

  const handleOpen = (rowData) => {
    if (rowData) {
      setIsEditMode(true);
      setSelectedRow(rowData);
      setFormValues({
        name: rowData.name,
        specially: rowData.specially,
        phone_number: rowData.phone_number,
        email: rowData.email,
        experience: rowData.experience,
        degrees: rowData.degrees,
        professional_summary: rowData.professional_summary,
        image_alt_text: rowData.image_alt_text,
      });
      setImagePreview(rowData.image);
      // Set SunEditor content
      setProfessionalSummaryContent(rowData.professional_summary);
    } else {
      setIsEditMode(false);
      setSelectedRow(null);
      setFormValues({
        name: "",
        specially: "",
        phone_number: "",
        email: "",
        experience: "",
        degrees: "",
        professional_summary: "",
        image: "",
        image_alt_text: "",
      });
      setImagePreview("");
      // Clear SunEditor content
      setProfessionalSummaryContent("");
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (isEditMode && selectedRow?.id) {
        await axios({
          method: "put",
          url: `https://api.smilecloudfamilydental.com/smile-dental/about_us/personal_information/${selectedRow.id}`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            name: formValues.name,
            specially: formValues.specially,
            phone_number: formValues.phone_number,
            email: formValues.email,
            experience: formValues.experience,
            degrees: formValues.degrees,
            professional_summary: professionalSummaryContent, // Use separate state for SunEditor content
            image: imagePreview,
            image_alt_text: formValues.image_alt_text,
          },
        });
      } else {
        await axios({
          method: "post",
          url: `https://api.smilecloudfamilydental.com/smile-dental/about_us/personal_information`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            name: formValues.name,
            specially: formValues.specially,
            phone_number: formValues.phone_number,
            email: formValues.email,
            experience: formValues.experience,
            degrees: formValues.degrees,
            professional_summary: professionalSummaryContent, // Use separate state for SunEditor content
            image: imagePreview,
            image_alt_text: formValues.image_alt_text,
          },
        });
      }
      toast.success("Update successful");
      fetchData();
      handleClose();
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error("Failed to update");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "professional_summary") {
      // Update separate state for SunEditor content
      setProfessionalSummaryContent(value);
    } else {
      // Update other form values normally
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.smilecloudfamilydental.com/smile-dental/about_us/personal_information"
      );
      setRows(response?.data);
    } catch (error) {
      toast.error(error);
      console.error("Error fetching the data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      {loading && <Loader />}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Professional Information</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen()}
        >
          Add Professional
        </Button>
      </Stack>

      <Card sx={{ padding: "20px" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Specially</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Image Alt Text</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.specially}</TableCell>
                  <TableCell>{row.phone_number}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    {row.image && (
                      <img
                        src={row.image}
                        alt={row.image_alt_text}
                        style={{ width: 50, height: 50 }}
                      />
                    )}
                  </TableCell>
                  <TableCell>{row.image_alt_text}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpen(row)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {isEditMode ? "Edit Professional" : "Add Professional"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label htmlFor="imageInput">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  border="1px dashed grey"
                  height={200}
                  width="100%"
                  style={{ cursor: "pointer" }}
                >
                  {imagePreview ? (
                    <img src={imagePreview} alt="Selected" height="200" />
                  ) : (
                    <Typography>Click to select an image</Typography>
                  )}
                </Box>
              </label>
              <input
                type="file"
                id="imageInput"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                name="image_alt_text"
                label="Image Alt Text"
                type="text"
                fullWidth
                value={formValues.image_alt_text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="name"
                label="Professional Name"
                type="text"
                fullWidth
                value={formValues.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="specially"
                label="Specially"
                type="text"
                fullWidth
                value={formValues.specially}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="phone_number"
                label="Phone Number"
                type="text"
                fullWidth
                value={formValues.phone_number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="email"
                label="Email"
                type="text"
                fullWidth
                value={formValues.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                name="experience"
                label="Experience"
                type="text"
                fullWidth
                value={formValues.experience}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                name="degrees"
                label="Degrees"
                type="text"
                fullWidth
                value={formValues.degrees}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <SunEditor
                setDefaultStyle="height: 200px"
                setContents={professionalSummaryContent} // Use separate state for SunEditor content
                onChange={setProfessionalSummaryContent} // Update separate state
                setOptions={{
                  buttonList: [
                    [
                      "bold",
                      "underline",
                      "italic",
                      "list",
                      "fontSize",
                      "fontColor",
                    ],
                  ],
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProfessionalInformation;
